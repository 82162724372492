<script setup lang="ts">
import { type Area } from "@gasparigit/omnia-sdk";
import { Check, ChevronsUpDown } from "lucide-vue-next";
import type { AcceptableValue } from "radix-vue/dist/shared/types";
import type { FieldSlotProps } from "vee-validate";
import { cn } from "~/lib/utils";
import type { FieldProps } from "../ui/auto-form";

defineProps<
  FieldProps & {
    areas: Area[];
  }
>();

function isChecked(area: AcceptableValue, slotProps: FieldSlotProps<AcceptableValue[]>) {
  return slotProps.value?.find((a) => a === area) ? true : false;
}
</script>

<template>
  <FormField v-slot="slotProps" :name="fieldName">
    <FormItem v-bind="$attrs">
      <AutoFormLabel v-if="!config?.hideLabel" :required="required">
        {{ config?.label }}
      </AutoFormLabel>
      <FormControl>
        <Popover class="w-full">
          <PopoverTrigger as-child>
            <Button variant="outline" role="combobox" class="w-full justify-between text-sm">
              {{ slotProps.value && slotProps.value.length ? slotProps.value.join(", ") : "Seleziona una o più aree..." }}
              <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent class="w-full p-0" align="start" style="width: var(--radix-popover-trigger-width)">
            <Command>
              <CommandEmpty>Nessun'area trovata.</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  <CommandItem
                    v-for="area in areas"
                    :key="area.id"
                    :value="area.title"
                    @select="
                      (ev) => {
                        const area = ev.detail.value;

                        if (!isChecked(area, slotProps)) {
                          slotProps.setValue([...(slotProps.value || []), area]);
                        } else {
                          slotProps.setValue(slotProps.value?.filter((a:AcceptableValue) => a !== ev.detail.value));
                        }
                      }
                    "
                  >
                    {{ area.title }}
                    <Check :class="cn('ml-auto h-4 w-4', isChecked(area.title, slotProps) ? 'opacity-100' : 'opacity-0')" />
                  </CommandItem>
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </FormControl>
      <FormDescription v-if="config?.description">
        {{ config.description }}
      </FormDescription>
      <FormMessage />
    </FormItem>
  </FormField>
</template>
