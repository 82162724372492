<script setup lang="ts">
import type { DemoRequest, DemoRequestType } from "@gasparigit/omnia-sdk";
import { z } from "zod";
import { processError, requiredError, successToast } from "~/lib/utils/errors";
import type { CreateLinkConfig } from "~/utils/types";
import AdministrationUnit from "../fields/AdministrationUnit.vue";
import AreasField from "../fields/Areas.vue";
import CheckboxPreference from "../fields/CheckboxPreference.vue";
import type { ConfigItem, FieldProps } from "../ui/auto-form";

const omniaSdk = useOmniaSdk();

const open = defineModel<boolean>("open");
const props = withDefaults(
  defineProps<{
    type?: DemoRequestType;
    linkConfig?: CreateLinkConfig;
  }>(),
  {
    type: "demo",
  }
);
const loading = ref<boolean>(false);

const { data: areas } = useAsyncData(`all-areas`, async () => {
  try {
    return await omniaSdk.v1.areas.list({ pagination: "none" });
  } catch (error) {
    processError(error);
  }
});

const formSchema = z
  .object({
    name: z.string(requiredError).describe("Nome"),
    surname: z.string(requiredError).describe("Cognome"),
    email: z.string(requiredError).trim().email("Email non valida").describe("Email"),
    email_confirmation: z.string(requiredError).trim().email("Email non valida").describe("Conferma email"),
    phone: z.string().describe("Telefono").optional(),
    ipa_code: z.string(requiredError).describe("Ente"),
    user_areas: z.array(z.string(), requiredError).min(1, "Seleziona almeno un'area di appartenenza").describe("Aree di appartenenza"),
    note: z.string().describe("Elenco email").optional(),
    newsletter_accepted: z.boolean().optional(),
    user_policy_accepted: z.boolean(requiredError).refine((value) => value, {
      message: "Devi accettare l'informativa sulla privacy.",
      path: ["user_policy_accepted"],
    }),
  })
  .refine((data) => data.email === data.email_confirmation, {
    message: "Email e conferma email devono corrispondere",
    path: ["email_confirmation"],
  });

const fieldConfig: Record<string, ConfigItem> = {
  name: {
    inputProps: { autocomplete: "given-name" },
  },
  surname: {
    inputProps: { autocomplete: "family-name" },
  },
  email: {
    inputProps: { autocomplete: "email" },
  },
  email_confirmation: {
    inputProps: { autocomplete: "email" },
  },
  phone: {
    inputProps: { autocomplete: "tel" },
  },
  ipa_code: {
    label: "Ente",
    component: AdministrationUnit,
  },
  note: {
    component: "textarea",
  },
  newsletter_accepted: {
    label: "Ricevi la newsletter delle novità Gaspari",
    component: CheckboxPreference,
  },
  user_policy_accepted: {
    label: "privacy_policy",
    component: CheckboxPreference,
  },
  user_areas: {
    label: "Aree di appartenenza",
    component: defineComponent(
      (props: FieldProps) => () =>
        h(AreasField, {
          areas: areas.value.data,
          ...props,
        })
    ),
  },
};

const form = useForm({
  validationSchema: toTypedSchema(formSchema),
});

const captchaToken = ref<string>();

async function onSubmit(values: Record<string, any>) {
  loading.value = true;

  try {
    const demoRequest = { ...values, type: props.type } as DemoRequest & { email_confirmation: string; captcha: string };

    demoRequest.ref = useRoute().fullPath;
    demoRequest.area_id = props.linkConfig?.area?.id;
    demoRequest.content_id = props.linkConfig?.content?.id;
    demoRequest.hub_id = props.linkConfig?.hub?.id;
    demoRequest.memoweb_id = props.linkConfig?.memoweb?.id;
    demoRequest.menu_ids = props.linkConfig?.chain?.map((m) => (typeof m === "number" ? m : m.id));
    demoRequest.captcha = captchaToken.value;

    await omniaSdk.v1.demoRequests.create(demoRequest);

    successToast("Richiesta inviata con successo");

    open.value = false;
  } catch (error) {
    processError(error);
  }

  loading.value = false;
}
</script>

<template>
  <Dialog v-model:open="open">
    <DialogScrollContent class="max-w-3xl" @pointer-down-outside.prevent>
      <DialogHeader>
        <DialogTitle>Richiesta {{ props.type === "demo" ? "demo" : "preventivo" }}</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <AutoForm @submit="onSubmit" :form="form" :schema="formSchema" :field-config="fieldConfig" class="content-card-grid grid gap-4">
        <div class="text-center">
          <NuxtTurnstile v-model="captchaToken" :options="{ theme: 'light' }" />
        </div>
        <DialogFooter class="mt-4">
          <DialogClose as-child>
            <Button type="reset" variant="ghost">Chiudi</Button>
          </DialogClose>
          <Button type="submit" :disabled="loading || !form.meta.value.valid" :class="{ 'animate-pulse': loading }">Invia</Button>
        </DialogFooter>
      </AutoForm>
    </DialogScrollContent>
  </Dialog>
</template>
